
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import EstimateService from "@/services/work/estimate.service";
import core from "@/core";
import UpdateEvent from "@/models";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "EstimateDownload",

  data: () => ({
    title: "",

    dateTimeFormatPattern: "YYYY년 MM월 DD일 HH시 mm분",

    form: {
      dateTimeType: "C",
      placeIdList: [] as any,
      searchDates: [],
      paymentYn: "",
      contractStatus: "",
      scheduleYn: "",
      // estimateDetailYn: "",
    },
    datePicker: {
      visible: false,
    },

    view: {
      placeName: "",
      searchDateText: "",
    },
    placeList: [] as any,

    // 선택된 아파트
    selectedPlace: null as any,

    placeTypeList: [] as any, // 선택된 아파트 타입 목록

    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      placeType: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  created() {
    this.title = "견적서 다운로드";

    const searchDates = this.form.searchDates as any;

    searchDates.push(core.date.instance().add(-1, "month").format("YYYY-MM-DD"));
    searchDates.push(core.date.instance().format("YYYY-MM-DD"));
  },
  mounted() {
    this.$nextTick(async () => {
      core.loader.show();
      try {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    });
  },
  computed: {
    today() {
      return core.date.instance().format("YYYY-MM-DD");
    },
    searchDateText() {
      const searchDates = this.form.searchDates as any;
      if (searchDates != null && searchDates.length > 1) {
        const moment1 = core.date.instance(searchDates[0]);
        const moment2 = core.date.instance(searchDates[1]);
        if (moment1.isAfter(moment2)) {
          const temp = searchDates[0];
          searchDates[0] = searchDates[1];
          searchDates[1] = temp;
        }
        return this.form.searchDates.join(" ~ ");
      }
      return "";
    },
  },
  watch: {
    "view.placeName"(val) {
      if (!this.isNotBlank(val)) {
        this.form.placeIdList = [];
      }
    },
  },
  methods: {
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
    async submit() {
      const params = {} as any;
      core.loader.show("파일 생성중...");
      console.log("this.form.placeIdList : ", this.form.placeIdList);
      if (this.form.placeIdList != null && this.form.placeIdList.length > 0) {
        params.placeIdList = this.form.placeIdList.join(",");
      }
      if (this.form.searchDates != null && this.form.searchDates.length > 1) {
        if (this.form.dateTimeType === "C") {
          params.createdAtGreaterThanEqual = this.form.searchDates[0];
          params.createdAtLessThan = core.date
            .instance(this.form.searchDates[1])
            .add(1, "days")
            .format("YYYY-MM-DD");
        } else if (this.form.dateTimeType === "U") {
          params.createdAtGreaterThanEqual = this.form.searchDates[0];
          params.createdAtLessThan = core.date
            .instance(this.form.searchDates[1])
            .add(1, "days")
            .format("YYYY-MM-DD");
        } else {
          params.finalConstructionAtGreaterThanEqual = this.form.searchDates[0];
          params.finalConstructionAtLessThan = core.date
            .instance(this.form.searchDates[1])
            .add(1, "days")
            .format("YYYY-MM-DD");
        }
      }
      if (this.isNotBlank(this.form.scheduleYn)) {
        params.scheduleYn = this.form.scheduleYn;
      }

      if (this.isNotBlank(this.form.contractStatus)) {
        params.contractStatus = this.form.contractStatus;
      }

      if (this.isNotBlank(this.form.paymentYn)) {
        params.paymentYn = this.form.paymentYn;
      }

      // if (this.isNotBlank(this.form.estimateDetailYn)) {
      //   params.estimateDetailYn = this.form.estimateDetailYn;
      // }

      try {
        console.log("params : ", params);
        const response = (await EstimateService.downloadExcel(params)) as any;
        // response.downloadUri;
        //console.log("response : ", response);
        core.loader.show("다운로드중...");
        await core.http.downloadFile(response.downloadUri);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
  },
});
